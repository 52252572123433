import { Presets } from '../index.d';

const degree = window?.HE?.contextualDefaults?.degree?.slug || '';
const category = window?.HE?.contextualDefaults?.category?.slug || '';
const subject = window?.HE?.contextualDefaults?.subject?.slug || '';
const presets: Presets = {
  'sonic-rankings': [
    {
      path: '@',
      attributes: {
        theme: 'primary',
      },
    },
  ],
  'sonic-qdf': [
    {
      path: '@',
      attributes: {
        _degree: degree === 'general' ? '' : degree,
        _category: category === 'general' ? '' : category,
        _subject: subject === 'general' ? '' : subject,
        publisher: window?.HE?.publisher?.name || '',
        url: window?.HE?.publisher?.appExperienceUrl || '',
        voyagerUrl: window?.HE?.publisher?.voyagerAppExperienceUrl || '',
      },
    },
  ],
  'sonic-editorial-listings': [
    {
      path: '@',
      attributes: {
        _degree: degree,
        _category: category,
        _subject: subject,
        publisher: window?.HE?.publisher?.name || '',
        url: window?.HE?.publisher?.appExperienceUrl || '',
      },
    },
  ],
  'sonic-editorial-listings-preamp': [
    {
      path: '@',
      attributes: {
        _degree: degree,
        _category: category,
        _subject: subject,
        publisher: window?.HE?.publisher?.name || '',
        url: window?.HE?.publisher?.appExperienceUrl || '',
      },
    },
  ],
  'sonic-paid-media': [
    {
      path: '@',
      attributes: {
        _degree: degree,
        _category: category,
        _subject: subject,
      },
    },
  ],
  'edu-product-shelf': [
    {
      path: '@',
      attributes: {
        _degree: degree === 'general' ? '' : degree,
        _category: category === 'general' ? '' : category,
        _subject: subject === 'general' ? '' : subject,
        publisher: window?.HE?.publisher?.name || '',
        url: window?.HE?.publisher?.appExperienceUrl || '',
      },
    },
  ],
};

export default presets;
