import { Constructor } from '../index.d';
import UCL from '../index';
import manifest from './manifest';
import defaultPresets from './presets';
import defaultScripts from './scripts';
import isDev from '../scripts/isDev';

class PublisherUCL extends UCL {
  /**
   * constructor
   *
   * @param {Constructor} props property with presets, manifest and path properties.
   */
  constructor(props: Constructor) {
    const { presets = {}, path = null, scripts = [] } = props || {};

    super({
      manifest,
      path,
      presets: {
        ...defaultPresets,
        ...presets,
      },
      scripts: [...defaultScripts, ...scripts],
      isDev: isDev(window.location.hostname),
    });
  }
}

window.HE_UCL = PublisherUCL;
