import {
  Manifest,
  Presets,
  Constructor,
  Script,
  ScriptDependOn,
  ScriptAppendTo,
} from './index.d';

export default class UCL {
  /**
   * path
   *
   * @type {string}
   */
  path: string;

  /**
   * manifest
   *
   * @type {Manifest}
   */
  manifest: Manifest = {};

  /**
   * isDev
   *
   * @type {boolean}
   */
  isDev: boolean;

  /**
   * presets
   *
   * @type {Presets}
   */
  presets: Presets = {};

  /**
   * scripts
   *
   * @type {Script[]}
   */
  scripts: Script[] = [
    {
      src: 'vendor.js',
      appendTo: 'body',
      dependOn: 'components',
    },
  ];

  /**
   * loaded
   *
   * @type {string[]}
   */
  loaded: string[] = [];

  /**
   * loadedDependOn
   *
   * @type {string[]}
   */
  loadedDependOn: string[] = [];

  /**
   * constructor
   */
  constructor(props: Constructor) {
    const {
      presets = {},
      path = null,
      manifest = {},
      scripts = [],
      isDev = false,
    } = props || {};

    // set defaults
    this.presets = presets || this.presets;
    this.manifest = manifest || this.manifest;
    this.scripts = this.scripts.concat(scripts);
    this.isDev = isDev;

    // return, if no manifest or scripts
    if (Object.keys(this.manifest).length === 0 && this.scripts.length === 0) {
      return;
    }

    // set HE_UCL to window
    this.setWindow();

    // set path
    this.setPath(path);

    // initialize
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  /**
   * init
   *
   * initialize UCL, this allows re-initialization on different ready events if needed
   */
  init(): void {
    this.loadAssets(this.queryScripts());
    this.loadComponents(this.queryComponents());
  }

  /**
   * loadComponents
   *
   * load all or specified components
   *
   * @param {array} components list of components to load
   */
  loadComponents(components: string[] = Object.keys(this.manifest)): void {
    components.forEach((component: string) => this.loadComponent(component));
  }

  /**
   * loadComponent
   *
   * add component script to the page
   *
   * @param {string} component component to load
   */
  loadComponent(component: string): void {
    // return, no component
    if (!component) {
      return;
    }

    // return, component not in manifest
    if (!Object.prototype.hasOwnProperty.call(this.manifest, component)) {
      // eslint-disable-next-line no-console
      console.warn(`UCL: Component <${component}> doesn't exists in manifest.`);
      return;
    }

    // return, component previously loaded or component already defined in CustomElementRegistry
    if (
      this.loaded.includes(component) ||
      window.customElements.get(component)
    ) {
      return;
    }

    // load component dependencies
    this.loadDependencies(component);

    // load depend on scripts
    this.loadAssets(this.queryScripts('components'));

    // load distribution(s)
    if (this.isDev && this.manifest?.[component]?.dev?.length) {
      // if in dev mode & there is a dev array, load those assets
      this.manifest[component].dev.forEach((scriptUrl) => {
        this.loadAsset(scriptUrl);
      });
    } else if (this.manifest?.[component]?.dist?.length) {
      this.manifest[component].dist.forEach((dist) => {
        this.loadAsset(dist);
      });
    }
  }

  /**
   * loadDependencies
   *
   * load child components specified in the manifest.
   *
   * @param {string} component component to check dependencies against
   */
  loadDependencies(component: string): void {
    const dependencies = this.manifest[component]?.dependencies || [];

    // load dependencies
    dependencies.forEach((dependency: string) => {
      // search manifest
      if (this.manifest[dependency]) {
        this.loadComponent(dependency);
        return;
      }
      // eslint-disable-next-line no-console
      console.warn(
        `UCL: Component <${dependency}> doesn't exists in manifest. A dependency of the <${component}> component.`
      );
    });
  }

  /**
   * queryComponents
   *
   * queryComponents the dom for components found in the manifest
   *
   * @return {string[]} string of components found on page
   */
  queryComponents(): string[] {
    return Object.entries(this.manifest)
      .filter(
        ([, properties]) =>
          !!document.querySelectorAll(properties.selector).length
      )
      .map(([component]) => component);
  }

  /**
   * setPresets
   *
   * add preset attributes to component.
   *
   * @param {string} component component to set preset attributes on
   * @param {Record<string, unknown>} customPreset a custom preset of attributes to be set
   */
  setPresets(
    component: string,
    customPreset: Record<string, unknown> = null
  ): void {
    const preset = customPreset || this.presets?.[component] || null;

    // return, if no component preset
    if (!preset) {
      return;
    }

    // update properties from preset
    Array.from(document.querySelectorAll(component)).forEach(
      (element: Element) => {
        Object.entries(preset).forEach(
          ([property, value]: [string, string]) => {
            // return, if null or empty
            if (value === null || value === '') {
              return;
            }

            // custom presets
            if (customPreset || !element.hasAttribute(property)) {
              // slot
              if (property === 'slot') {
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = value;
                return;
              }

              // property
              element.setAttribute(property, value);
            }
          }
        );
      }
    );
  }

  /**
   * loadAssets
   *
   * load all or specified scripts onto page dom
   *
   * @param {array} components list of scripts to load into dom
   */
  loadAssets(scripts: Script[] = this.scripts): void {
    scripts.forEach((script) => {
      this.loadAsset(script.src, script.appendTo, script.properties);
    });
  }

  /**
   * loadAsset
   *
   * load script into dom
   *
   * @param {string} src script source to add
   * @param {string} appendTo where to add the script to document "body or head"
   * @param {object} properties additional properties/attributes to add to script
   */
  loadAsset(
    src: string,
    appendTo: ScriptAppendTo = 'body',
    properties = {}
  ): void {
    switch (true) {
      case src.endsWith('.css'):
        this.loadStyle(src, appendTo, properties);
        break;
      default:
      case src.endsWith('.js'):
        this.loadScript(src, appendTo, properties);
    }
  }

  /**
   * loadScript
   *
   * load script into dom
   *
   * @param {string} src script source to add
   * @param {string} appendTo where to add the script to document "body or head"
   * @param {object} properties additional properties/attributes to add to script
   */
  loadScript(
    src: string,
    appendTo: ScriptAppendTo = 'body',
    properties = {}
  ): void {
    // return, script already loaded or append to is not head or body
    if (this.loaded.includes(src) || !['head', 'body'].includes(appendTo)) {
      return;
    }

    // loaded
    this.loaded.push(src);

    // script append to DOM
    document[appendTo].appendChild(
      Object.assign(document.createElement('script'), {
        src: src.startsWith('http') ? src : `${this.path}/${src}`,
        ...properties,
      })
    );
  }

  /**
   * loadStyle
   *
   * load script into dom
   *
   * @param {string} href script source to add
   * @param {string} appendTo where to add the script to document "body or head"
   * @param {object} properties additional properties/attributes to add to script
   */
  loadStyle(
    href: string,
    appendTo: ScriptAppendTo = 'head',
    properties = {}
  ): void {
    // return, styles already loaded or append to is not head or body
    if (this.loaded.includes(href) || !['head', 'body'].includes(appendTo)) {
      return;
    }

    // loaded
    this.loaded.push(href);

    // styles append to DOM
    document[appendTo].appendChild(
      Object.assign(document.createElement('link'), {
        type: 'text/css',
        rel: 'stylesheet',
        href: href.startsWith('http') ? href : `${this.path}/${href}`,
        ...properties,
      })
    );
  }

  /**
   * queryScripts
   *
   * query scripts by depend on groupings
   *
   * @param {string} dependOn name of the depend on grouping
   * @return {Script[]} list of scripts to add to page
   */
  queryScripts(dependOn: ScriptDependOn = null): Script[] {
    // return, script already loaded
    if (this.loadedDependOn.includes(dependOn)) {
      return [];
    }

    // loaded
    this.loadedDependOn.push(dependOn);

    // return scripts
    return this.scripts.filter(
      (script) =>
        dependOn === 'all' ||
        (!dependOn && !script.dependOn) ||
        dependOn === script.dependOn
    );
  }

  /**
   * setPath
   *
   * setup the correct path to component scripts
   *
   * @param {string} path the source of the component scripts to load.
   */
  setPath(path: string): void {
    let src = path;

    // no path, default to ucl loader path
    if (!path) {
      const scripts = [
        document.querySelector('script#ucl-loader'),
        ...document.querySelectorAll('script[src*="/ucl."]'),
      ].filter(
        (script: HTMLScriptElement) =>
          script && !script.src.includes('ucl.adapter.js')
      );

      if (scripts.length) {
        const url = (scripts[0] as HTMLScriptElement).src;
        src = url.substring(0, url.lastIndexOf('/'));
      }
    }

    this.path = src.replace(/\/$/, '');
  }

  /**
   * setWindow
   *
   * set the window object HE_UCL for additional helper methods and
   * properties.
   *
   * @param {string} writeKey write key for context
   */
  setWindow(): void {
    window.HE_UCL = this;
  }
}
