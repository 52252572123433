import { Manifest } from '../index.d';

const manifest: Manifest = {
  voyager: {
    dist: [
      'https://voyager-experiences.rveducation.io/latest/voyager.bundle.js',
      'https://voyager-experiences.rveducation.io/latest/voyager.bundle.css',
    ],
    dev: [
      'https://voyager-experiences.staging.rveducation.io/latest/voyager.bundle.js',
      'https://voyager-experiences.staging.rveducation.io/latest/voyager.bundle.css',
    ],
    selector: '#voyager',
  },
  studymatch: {
    dist: [
      'https://studymatch3.highereducation.com/widgets/studymatch/studymatch.bundle.js',
      'https://studymatch3.highereducation.com/widgets/studymatch/studymatch.css',
    ],
    selector: '#studymatch',
  },
  'edu-product-shelf': {
    dist: [
      'https://sonic-ui.highereducation.com/widgets/latest/edu-product-shelf.js',
    ],
    selector: 'edu-product-shelf',
  },
  'sonic-accordion': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-accordion.js'],
    selector: 'sonic-accordion',
  },
  'sonic-bio': {
    dist: ['sonic-bio.js'],
    selector: 'sonic-bio',
  },
  'sonic-blockquote': {
    dist: ['sonic-blockquote.js'],
    selector: 'sonic-blockquote',
  },
  'sonic-branded-disclosure': {
    dist: ['sonic-branded-disclosure.js'],
    selector: 'sonic-branded-disclosure',
  },
  'sonic-callout': {
    dist: ['sonic-callout.js'],
    selector: 'sonic-callout',
  },
  'sonic-card': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-card.js'],
    selector: 'sonic-card',
  },
  'sonic-certificate': {
    dist: ['sonic-certificate.js'],
    selector: 'sonic-certificate',
  },
  'sonic-checklist': {
    dist: ['sonic-checklist.js'],
    selector: 'sonic-checklist',
  },
  'sonic-checklist-item': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-checklist-item.js'],
    selector: 'sonic-checklist-item',
  },
  'sonic-columns': {
    dist: ['sonic-columns.js'],
    selector: 'sonic-columns',
  },
  'sonic-comparison-grid': {
    dist: ['sonic-comparison-grid.js'],
    selector: 'sonic-comparison-grid',
  },
  'sonic-cta': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-cta.js'],
    selector: 'sonic-cta',
  },
  'sonic-cta-widget': {
    dependencies: ['sonic-tooltip', 'sonic-cta'],
    dist: ['sonic-cta-widget.js'],
    selector: 'sonic-cta-widget',
  },
  'sonic-day-in-the-life': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-day-in-the-life.js'],
    selector: 'sonic-day-in-the-life',
  },
  'sonic-editorial-listings': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-editorial-listings.js'],
    selector: 'sonic-editorial-listings, he-editorial-listings',
  },
  'sonic-editorial-listings-preamp': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-editorial-listings-preamp.js'],
    selector: 'sonic-editorial-listings-preamp',
  },
  'sonic-email-capture': {
    dist: ['sonic-email-capture.js'],
    selector: 'sonic-email-capture',
  },
  'sonic-expandable': {
    dist: ['sonic-expandable.js'],
    selector: 'sonic-expandable',
  },
  'sonic-icon': {
    dist: ['sonic-icon.js'],
    selector: 'sonic-icon',
  },
  'sonic-icon-percentage': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-icon-percentage.js'],
    selector: 'sonic-icon-percentage',
  },
  'sonic-interlink': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-interlink.js'],
    selector: 'sonic-interlink',
  },
  'sonic-link-card': {
    dist: ['sonic-link-card.js'],
    selector: 'sonic-link-card',
  },
  'sonic-modal': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-modal.js'],
    selector: 'sonic-modal',
  },
  'sonic-paid-media': {
    dependencies: ['sonic-icon'],
    dist: ['sonic-paid-media.js'],
    selector: 'sonic-paid-media',
  },
  'sonic-percentage': {
    dist: ['sonic-percentage.js'],
    selector: 'sonic-percentage',
  },
  'sonic-photo-collage': {
    dist: ['sonic-photo-collage.js'],
    selector: 'sonic-photo-collage',
  },
  'sonic-posts': {
    dist: ['sonic-posts.js'],
    selector: 'sonic-posts',
  },
  'sonic-pullquote': {
    dist: ['sonic-pullquote.js'],
    selector: 'sonic-pullquote',
  },
  'sonic-qdf': {
    dependencies: ['sonic-tooltip', 'sonic-icon'],
    dist: ['sonic-qdf.js'],
    selector: 'sonic-qdf, he-qdf',
  },
  'sonic-rankings': {
    dependencies: ['sonic-tooltip', 'sonic-icon'],
    dist: ['sonic-rankings.js'],
    selector: 'sonic-rankings',
  },
  'sonic-sponsored-cards': {
    dependencies: ['sonic-tooltip', 'sonic-icon'],
    dist: ['sonic-sponsored-cards.js'],
    selector: 'sonic-sponsored-cards',
  },
  'sonic-rankings-toc': {
    dist: ['sonic-rankings-toc.js'],
    selector: 'sonic-rankings-toc',
  },
  'sonic-resource-list': {
    dist: ['sonic-resource-list.js'],
    selector: 'sonic-resource-list',
  },
  'sonic-school-comparison': {
    dist: ['sonic-school-comparison.js'],
    selector: 'sonic-school-comparison',
  },
  'sonic-school-search': {
    dist: ['sonic-school-search.js'],
    selector: '#sonic-school-search',
  },
  'sonic-slider': {
    dist: ['sonic-slider.js'],
    selector: 'sonic-slider',
  },
  'sonic-stat-box': {
    dist: ['sonic-stat-box.js'],
    selector: 'sonic-stat-box',
  },
  'sonic-table': {
    dist: ['sonic-table.js'],
    selector: 'sonic-table',
  },
  'sonic-tabs': {
    dist: ['sonic-tabs.js'],
    selector: 'sonic-tabs',
  },
  'sonic-tooltip': {
    dist: ['sonic-tooltip.js'],
    selector: 'sonic-tooltip',
  },
  'sonic-wage-calculator': {
    dist: ['sonic-wage-calculator.js'],
    selector: 'sonic-wage-calculator',
  },
};

export default manifest;
