/**
 * isDev.js
 *
 * Utility for determining the environment the UCL is loaded on.
 * Will be used to load dev/staging bundles onto dev environments.
 */

export default (host: string): boolean => {
  // Check if BC dev
  if (host === 'dev.bestcolleges.com') {
    return true;
  }

  // check if host contains dev- and pantheon
  if (host.includes('dev-') && host.includes('pantheonsite')) {
    return true;
  }

  // check if local
  if (host === 'localhost' || host === '127.0.0.1') {
    return true;
  }

  // if no dev requirements are met, return false
  return false;
};
